import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import React, { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { auth } from 'store';

interface IProps {
  placeholder: string;
  icon?: any;
  type?: string;
  rows?: number;
  name: string;
  value?: string;
  endAdornment?: any;
  multiline?: boolean;
}

const CustomInput = ({
  placeholder,
  icon,
  type,
  rows,
  name,
  value,
  ...rest
}: IProps) => {
  const dispatch = useDispatch();
  const handleChange =
    (props: string) => (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      dispatch(auth({ [props]: event.target.value }));
    };

  return (
    <Paper variant="outlined" sx={{ px: 2, py: 1.5 }}>
      <InputBase
        sx={{ width: '100%' }}
        placeholder={placeholder}
        type={type}
        rows={rows}
        value={value}
        onChange={handleChange(name)}
        multiline={rows ? true : false}
        startAdornment={
          <InputAdornment position="start">{icon}</InputAdornment>
        }
        {...rest}
      />
    </Paper>
  );
};

export default CustomInput;

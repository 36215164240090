import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { API } from '_helpers';

const Article: React.FC<IProps<IArticle>> = ({ data }) => {
  const router = useRouter();
  return (
    <Grid item xs={12}>
      <Box sx={{ height: { xs: 280, sm: 420, md: 520 }, position: 'relative' }}>
        <Image
          alt={data?.media?.alternativeText}
          src={`${API}${data?.media?.url}`}
          layout="fill"
          objectFit="cover"
        />
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography component="h2" variant="h2" gutterBottom>
          {data?.title}
        </Typography>
        <Stack
          spacing={2}
          divider={<Divider orientation="vertical" flexItem />}
          direction="row"
        >
          <Typography color="text.secondary">
            {dayjs(data?.updatedAt)
              .locale(`${router.locale}`)
              .format('DD MMMM YYYY')}
          </Typography>
          <Link
            href={`/${router?.asPath.split('/')[1]}/${data?.category?.slug}`}
            passHref
          >
            <Typography color="secondary" component="a">
              {data?.category?.name}
            </Typography>
          </Link>
          <Typography color="text.secondary">
            {router.locale === 'fr' ? 'Par' : 'By'} {data?.author?.name}
          </Typography>
        </Stack>
      </Box>
      <Typography
        color="text.secondary"
        component="div"
        textAlign="justify"
        className="content"
        dangerouslySetInnerHTML={{
          __html: data?.content,
        }}
      />
    </Grid>
  );
};

export default Article;

import { styled } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
// ? Material Ui
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';
// ? Next js
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { API } from '_helpers';

dayjs.extend(relativeTime);

const Item = styled(Card)(({ theme }) => ({
  '&:hover': {
    boxShadow: '0 15px 25px 0 rgba(0,0,0,0.1)',
    background: 'rgba(255, 255, 255, 0.1)',
  },
  borderRadius: 10,
  justifyContent: 'center',
  minHeight: 480,
  width: '100%',
}));

const BlogCard: React.FC<IProps<IArticle>> = ({ data }) => {
  const router = useRouter();
  const handleClick = (href: string) => {
    router.push(href);
  };
  return (
    <Item variant="outlined">
      <CardActionArea
        onClick={() =>
          handleClick(`/blog/${data?.category?.slug}/${data?.slug}`)
        }
      >
        <Box sx={{ position: 'relative', height: 220 }}>
          <Image
            alt={data?.media?.alternativeText}
            src={`${API}${data?.media.url}`}
            layout="fill"
            objectFit="cover"
            quality={100}
          />
        </Box>
        <CardContent>
          <Link href="/tag" passHref>
            <Typography
              gutterBottom
              variant="h6"
              component="a"
              color="secondary"
            >
              {data?.category?.name}
            </Typography>
          </Link>
          <Typography
            gutterBottom
            variant="h3"
            noWrap
            component="h2"
            sx={{ my: 2 }}
          >
            {data?.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {data?.description}
          </Typography>
          <List>
            <ListItem disablePadding>
              <ListItemAvatar>
                <Avatar>
                  <Image
                    alt={data?.author?.media?.alternativeText}
                    src={`${API}${data?.author?.media.url}`}
                    width={data?.author?.media?.width}
                    height={data?.author?.media?.height}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={data?.author?.name}
                secondary={dayjs()
                  .locale(`${router.locale}`)
                  .to(data?.updatedAt)}
              />
            </ListItem>
          </List>
        </CardContent>
      </CardActionArea>
    </Item>
  );
};

export default BlogCard;

// ? Icons
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { alpha, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// ? Material Ui
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import React from 'react';
import BlogCard from '../blogCard';

interface IHomeBlog {
  title: string;
  description: string;
  articles: IArticle[];
  button: IButton;
}

const HomeBlog: React.FC<IProps<IHomeBlog>> = ({ data }) => {
  const router = useRouter();
  const handleClick = (href: string) => {
    router.push(href);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        py: 6,
        bgcolor: (theme) =>
          theme.palette.mode === 'dark'
            ? `${alpha(theme.palette.action.active, 0.02)}`
            : theme.palette.grey.A200,
      }}
    >
      <Container>
        <Stack spacing={2} sx={{ px: { xs: 0, md: 12, lg: 20 } }}>
          <Typography
            component="h2"
            color="text.primary"
            variant="h2"
            textAlign="center"
            gutterBottom
          >
            {data.title}
          </Typography>
          <Typography color="text.secondary" textAlign="center" gutterBottom>
            {data.description}
          </Typography>
        </Stack>
        <Grid container spacing={6} justifyContent="center" sx={{ my: 2 }}>
          {data.articles.map((el) => (
            <Grid item xs={12} sm={6} lg={4} key={el._id}>
              <BlogCard data={el} />
            </Grid>
          ))}
        </Grid>

        <Stack justifyContent="center" alignItems="center" sx={{ p: 4 }}>
          <Button
            sx={{
              my: 2,
              background: (theme) => alpha(theme.palette.primary.main, 0.1),
            }}
            onClick={() => handleClick(`${data.button.path}`)}
            color="primary"
            disableElevation
            component="a"
            endIcon={<ArrowRightAltIcon />}
          >
            {data.button.label}
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default HomeBlog;

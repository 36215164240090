import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useDarkMode } from 'next-dark-mode';
import React from 'react';
import { Timeline } from 'react-twitter-widgets';

const SideBar: React.FC<ISideBar> = ({ tags, sideBarData, children }) => {
  const { darkModeActive } = useDarkMode();

  return (
    <Paper elevation={0} sx={{ width: '100%' }}>
      {/* <Box
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      >
        <CustomInput
          placeholder={sideBarData.search}
          name="search"
          icon={<SearchIcon />}
        />
      </Box> */}
      <Typography component="h2" variant="h3" gutterBottom sx={{ mt: 6 }}>
        {sideBarData.categories}
      </Typography>
      {children}
      <Paper
        sx={{
          my: 2,
          minHeight: 400,
        }}
      >
        <Timeline
          dataSource={{
            sourceType: 'profile',
            screenName: 'DevelopersUp',
          }}
          options={{
            height: '500',
            theme: darkModeActive ? 'dark' : 'light',
          }}
        />
      </Paper>
      <Paper
        sx={{
          my: 2,
          overflowX: 'auto',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          '&': {
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          },
        }}
      >
        <iframe
          src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fweb.facebook.com%2FDevelopersUp&tabs=timeline&width=380&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=294728649196400`}
          height={500}
          style={{ width: '100%' }}
          frameBorder={0}
          allowFullScreen={true}
          loading="lazy"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        />
      </Paper>
      <Typography component="h2" variant="h3" gutterBottom sx={{ mt: 6 }}>
        {sideBarData?.tags}
      </Typography>
      <Grid container spacing={1}>
        {tags?.map((el, i) => (
          <Grid item xs="auto" key={el._id}>
            <Chip label={el.name} variant="outlined" />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default SideBar;

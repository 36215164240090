// ? Material Ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useRouter } from 'next/router';
import BlogCard from '../blogCard';

interface IPosts {
  data: IArticle[];
  page: number;
  count: number;
}

const ArticlesList: React.FC<IPosts> = ({ data, page, count }) => {
  const router = useRouter();
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    router.push(`${router.asPath.split('?')[0]}?page=${value}`);
  };
  return (
    <Box sx={{ flexGrow: 1, width: '100%' }}>
      <Grid item container spacing={4} xs={12}>
        {data.map((el, i) => (
          <Grid item xs={12} md={6} key={el._id}>
            <BlogCard data={el} />
          </Grid>
        ))}
      </Grid>
      <Stack alignItems="center" justifyContent="center" sx={{ my: 8 }}>
        <Pagination
          count={count}
          defaultPage={page}
          boundaryCount={2}
          shape="rounded"
          color="primary"
          onChange={handleChange}
        />
      </Stack>
    </Box>
  );
};

export default ArticlesList;

// ? Material Ui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useRouter } from 'next/router';
import React from 'react';
import SideBar from '../../../sideBar';

interface ILC extends ICategory {
  articles: IArticle[];
}

const BlogLayout: React.FC<ILayout<ILC>> = ({
  children,
  sideBar,
  categories,
  tags,
}) => {
  const router = useRouter();
  const handleClick = (href: string) => {
    router.push(href);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        py: 10,
      }}
    >
      <Container>
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12} container md={8}>
            {children}
          </Grid>
          <Grid container item xs={12} sm={6} md={4}>
            <SideBar tags={tags} sideBarData={sideBar}>
              <List>
                {categories?.map((el, i) => (
                  <div key={el._id}>
                    <ListItemButton
                      disableGutters
                      onClick={() => handleClick(`/blog/${el.slug}`)}
                    >
                      <ListItemText primary={el.name} />
                      {el.articles.length}
                    </ListItemButton>
                    <Divider />
                  </div>
                ))}
              </List>
            </SideBar>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BlogLayout;
